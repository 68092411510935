
//import { FirebaseApp } from "firebase/app";
//import { getFirestore, CollectionReference, collection, DocumentData, doc, setDoc, connectFirestoreEmulator } from 'firebase/firestore'

import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check'

//import { Functions } from 'firebase/functions';
// import { Auth } from "firebase/auth";
import { FirebaseApp, FirebaseOptions, getApp, initializeApp } from "firebase/app";
import { HoopOrder } from "./Order";
// import { Analytics, logEvent } from "firebase/analytics";
import { isReactSnap } from "./isReactSnap";
import { useEffect, useState } from "react";
import { Functions, connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import { Analytics, getAnalytics, logEvent, setConsent } from "firebase/analytics";
import { Auth, connectAuthEmulator, getAuth } from "firebase/auth";

// Your web app's Firebase configuration

// type FirebaseApp = ReturnType<typeof import('firebase/app').initializeApp>;
// type Analytics = ReturnType<typeof import('firebase/analytics').getAnalytics>;
// type Functions = ReturnType<typeof import('firebase/functions').getFunctions>;
// type Auth = ReturnType<typeof import('firebase/auth').getAuth>;

const debugMode = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";

// const firebaseConfig = {

//   apiKey: "AIzaSyC6vpQsg-k6Cx195_KsHIDg2zWG6z4Icz8",

//   authDomain: "hoop-artisan.firebaseapp.com",

//   projectId: "hoop-artisan",

//   storageBucket: "hoop-artisan.appspot.com",

//   messagingSenderId: "1003958521230",

//   appId: "1:1003958521230:web:3dc2c658b84957b3b828df"

// };
const firebaseConfig = {

  apiKey: "AIzaSyC6vpQsg-k6Cx195_KsHIDg2zWG6z4Icz8",
  authDomain: "hoop-artisan.firebaseapp.com",
  databaseURL: "https://hoop-artisan-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "hoop-artisan",
  storageBucket: "hoop-artisan.appspot.com",
  messagingSenderId: "1003958521230",
  appId: "1:1003958521230:web:3dc2c658b84957b3b828df",
  measurementId: "G-MDZW5HHZX6"

};



// let firebaseModules : {
//   //  appModule : typeof import('firebase/app'),
//   //  authModule : typeof import('firebase/auth') ,
//   //  appCheckModule : typeof import('firebase/app-check') ,
//   //  functionsModule : typeof import('firebase/functions') ,
//   //  analyticsModule : typeof import('firebase/analytics') ,
// } | null = null;

// export async function getFirebaseClient() {
//   if (firebaseModules) return firebaseModules
//   // const appModule = await import('firebase/app')

//   // const modules = await Promise.all([
//   //   import('firebase/auth'),
//   //   import('firebase/app-check'),
//   //   import('firebase/functions'),
//   //   import('firebase/analytics'),
//   // ])

//   firebaseModules = {
//     // appModule: appModule,
//     // authModule: modules[0],
//     // appCheckModule: modules[1],
//     // functionsModule: modules[2],
//     // analyticsModule: modules[3]
//   }

//   return firebaseModules;
// }

type FirebaseObjects = {
  app : FirebaseApp,
  auth : Auth,
  functions: Functions,
  analytics: Analytics
};

let firebasePromise : Promise<FirebaseObjects> | null = null;

function createFirebaseApp(config: FirebaseOptions) {
  try {
    return getApp();
  } catch {
    return initializeApp(config);
  }
}

function initFirebaseSync() {
    //const firebase = await getFirebaseClient();
    const app = initializeApp(firebaseConfig);

    //setConsent({ad_storage: "denied", analytics_storage: "denied", personalization_storage: "denied"});

    const auth = getAuth(app);
    const analytics = getAnalytics(app);

    setConsent({
      ad_storage: "denied", 
      analytics_storage: "denied", 
      personalization_storage: "denied",
      ad_user_data: 'denied',
      ad_personalization: 'denied',
    });

    const functions = getFunctions(app);
    if (debugMode) {

      console.log("CONNECT TO EMULATOR: reactsnap active? ", isReactSnap);
      connectFunctionsEmulator(functions, 'localhost', 5001);
      //connectFirestoreEmulator(firestore, 'localhost', 8080);

      connectAuthEmulator(auth, 'http://localhost:9099', {disableWarnings: isReactSnap});

      // eslint-disable-next-line no-restricted-globals
      (self as any).FIREBASE_APPCHECK_DEBUG_TOKEN = true;
      // const appCheck = initializeAppCheck(app, { provider: });
    }

    if (!isReactSnap)
    {
      const appCheck = initializeAppCheck(app, {
        provider: new ReCaptchaV3Provider('6LdtlVgjAAAAAOB7jtJiT4KnD1T0PQXW6xMHol2k'),
        // Optional argument. If true, the SDK automatically refreshes App Check
        // tokens as needed.
        isTokenAutoRefreshEnabled: true,
      });
    }


    return {
      app,auth, analytics, functions
    }
}

let firebaseObjects : FirebaseObjects = initFirebaseSync();

export function getFirebase() {
  return firebaseObjects;
  // if (firebaseObjects) return firebaseObjects;


  // if (firebasePromise) {
  //   // avoid multiple inits.
  //   return await firebasePromise;
  // }

  // firebasePromise = new Promise<FirebaseObjects>(async resolve => {

  //   resolve(firebaseObjects);
  // });

  // return firebasePromise;

}

export function getMyAnalytics() {
  return (getFirebase()).analytics;
}

export async function getMyAuth() {
  return (getFirebase()).auth;
}


export function useAuth() {

  const [auth, setAuth] = useState<Auth|null>(null);

  useEffect(() => {
    let canceled = {val : false};
    getMyAuth().then(a => {
      if (!canceled.val)
        setAuth(a);
    });
    return () => { canceled.val = true; } 
  }, []);
  
  return auth;
}

// const firebaseConfigDebug = {
//   apiKey: "firebase-mock-api-key",
//   authDomain: "localhost",
//   databaseURL: "http://localhost:9000?ns=hoop-artisan",
//   projectId: "hoop-artisan",
//   storageBucket: "hoop-artisan.appspot.com",
//   messagingSenderId: "firebase-mock-sender-id",
//   appId: "firebase-mock-app-id"
// };

// Initialize Firebase
// console.log(debugMode ? firebaseConfigDebug : firebaseConfig);

// old -----------------------


// const app = createFirebaseApp(firebaseConfig);

// export const analytics = getAnalytics(app);
// setConsent({ad_storage: "denied", analytics_storage: "denied", personalization_storage: "denied"});

// let functions : Functions | null = null;

// export function firebaseFunctionInstance() {
//   if (functions === null)
//     functions = getFunctions(app);
//   return functions;
// }

// //const firestore = getFirestore(app);
// // Initialize Firebase Authentication and get a reference to the service
// export const auth = getAuth(app);

// if (debugMode) {

//   console.log("CONNECT TO EMULATOR: reactsnap active? ", isReactSnap);
//   connectFunctionsEmulator(firebaseFunctionInstance(), 'localhost', 5001);
//   //connectFirestoreEmulator(firestore, 'localhost', 8080);

//   connectAuthEmulator(auth, 'http://localhost:9099', {disableWarnings: isReactSnap});

//   // eslint-disable-next-line no-restricted-globals
//   (self as any).FIREBASE_APPCHECK_DEBUG_TOKEN = true;
//   // const appCheck = initializeAppCheck(app, { provider: });
// }
// // else {

// // Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// // key is the counterpart to the secret key you set in the Firebase console.

// // initializeAppCheck(app, { /* App Check options */ });

// if (!isReactSnap)
// {
//   const appCheck = initializeAppCheck(app, {
//     provider: new ReCaptchaV3Provider('6LdtlVgjAAAAAOB7jtJiT4KnD1T0PQXW6xMHol2k'),
//     // Optional argument. If true, the SDK automatically refreshes App Check
//     // tokens as needed.
//     isTokenAutoRefreshEnabled: true,
//   });
// }

// // }


// ===================================

export async function preorderHoop(order : HoopOrder) {
//   <!-- Event snippet for party_hoop_order conversion page -->
// <script>
//   gtag('event', 'conversion', {
//       'send_to': 'AW-995284560/zynCCJDc0wMQ0KzL2gM',
//       'value': 1.0,
//       'currency': 'EUR'
//   });
// </script>
  // const {functionsModule, analyticsModule} = await getFirebaseClient();
  
  const {analytics, functions} = (getFirebase());
  if (order.options)
    logEvent(analytics, 'party_hoop_order');
  else
    logEvent(analytics, 'performer_hoop_order');


  logEvent(analytics, 'conversion', {
    send_to: 'AW-995284560/zynCCJDc0wMQ0KzL2gM',
    value: 200,
    currency: "EUR"
  });
  return httpsCallable(functions, 'preorderHoop')(order);
}

export async function sendContactMail(email: string, message: string) {
  //const {functionsModule} = await getFirebaseClient();
  const {functions} = ( getFirebase());
  return httpsCallable(functions, 'contactMail')({
    email: email,
    message: message
  });
}

export async function validatePromotionCode(code : string) {
  //const {functionsModule} = await getFirebaseClient();
  const {functions} = ( getFirebase());
  return httpsCallable<{code: string}, number>(functions, 'validatePromotionCode')({code});
}

export async function checkoutHoopOrder(order: HoopOrder) {
 // const {functionsModule} = await getFirebaseClient();
  const {functions} = ( getFirebase());

  return httpsCallable<HoopOrder, string>(functions, 'createCheckout')(order);
}

// This is just a helper to add the type to the db responses
// const createCollection = <T = DocumentData>(collectionName: string) => {
//   return collection(firestore, collectionName) as CollectionReference<T>
// }


// export interface ContactMessage {
//   email: string,
//   message: string
// };

// export const collections = {
//   doc: doc,
//   setDoc: setDoc,
//   contactInbox: createCollection<ContactMessage>('contactInbox')
// }

// start firebase init.
getFirebase();
