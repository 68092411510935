import { BiTime } from "react-icons/bi";
import { BsBatteryCharging } from "react-icons/bs";
import { FiBluetooth, FiRefreshCw } from "react-icons/fi";
import { GiMagicPortal, GiSmartphone, GiBattery75 } from "react-icons/gi";
import {
  IoLogoApple,
  IoLogoAndroid,
  IoMdClock,
} from "react-icons/io";
import { MdLightbulbOutline, MdSettings, MdBatteryFull, MdShapeLine, MdLock } from "react-icons/md";
import { useIntl, FormattedMessage } from "react-intl";
import { Carousel } from "react-responsive-carousel";
import { CarouselNavButton } from "./CarouselNavButton";
import { FlexColumn, Flex, FlexRow } from "./Flexbox";
import { SectionTitle } from "./SectionTitle";
import { SpacerV, SpacerH } from "./Spacer";
import { styles, ligthBorderPinkLight, centerFlex, ligthBorder } from "./style";
import { Shop } from "./Shop";
import { ContactSection } from "./ContactSection";
import { BigActionButton } from "./BigActionButton";
import { useOpenGraphMetaTags } from "./OpenGraphTags";
import { RootContainer } from "./RootContainer";

const HoopSection = () => {
  const intl = useIntl();
  return (
    <div
      id="theHoop"
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {/* <SectionTitle skipLineSeparator={true}>
        <img
          src={require("./icons/live.png")}
          width={50}
          style={{ marginRight: 30, color: Palette.pink }}
        />
        <FormattedMessage defaultMessage="Le cerceau" />
      </SectionTitle> */}

      {/* <img src="/hoop1.jpg" width={500} style={{ borderWidth: 0 }} /> */}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          // flexWrap: "wrap",
          alignItems: "center",
          marginTop: -20,
        }}
      >
        {/* <img
          src="/photo1.png"
          // src="/red_mode.png"
          alt={intl.formatMessage({
            defaultMessage: "Réalisée avec le mode lightpainting",
          })}
          style={{ width: 400 }}
        /> */}
        {/* TECHNOLOGY  */}
        <div
          style={{
            ...styles.hoopSpecBlock,
          }}
        >
          <div
            className="big-font"
            style={{
              ...styles.hoopSpecTitle,
            }}
          >
            <GiMagicPortal size={50} style={{ marginRight: 10 }} />
            <FormattedMessage defaultMessage="Fonctionnalités" />
          </div>

          <div style={{ ...styles.hoopSpecRow }}>
            <MdBatteryFull
              size={20}
              style={{ ...styles.hoopSpecRowIcon }}
            />
            <div style={{ ...styles.hoopSpecRowText }}>
              <FormattedMessage defaultMessage="Batterie remplaçable et rechargable" />
            </div>
          </div>

          <div style={{ ...styles.hoopSpecRow }}>
            <MdLightbulbOutline
              size={20}
              style={{ ...styles.hoopSpecRowIcon }}
            />
            <div style={{ ...styles.hoopSpecRowText }}>
              <FormattedMessage defaultMessage="30 leds / mètre ou 60 leds / mètre - Gap de 7.5cm environ" />
            </div>
          </div>
          <div style={{ ...styles.hoopSpecRow }}>
            <MdShapeLine size={20} style={{ ...styles.hoopSpecRowIcon }} />
            <div style={{ ...styles.hoopSpecRowText }}>
              <FormattedMessage defaultMessage="50 modes uniques" />
            </div>
          </div>
          <div style={{ ...styles.hoopSpecRow }}>
            <IoMdClock size={20} style={{ ...styles.hoopSpecRowIcon }} />
            <div style={{ ...styles.hoopSpecRowText }}>
              <FormattedMessage defaultMessage="Synchronisation de plusieurs cerceaux" />
            </div>
          </div>
          <div style={{ ...styles.hoopSpecRow }}>
            <MdLock size={20} style={{ ...styles.hoopSpecRowIcon }} />
            <div style={{ ...styles.hoopSpecRowText }}>
              <FormattedMessage defaultMessage="Verrouillage sur un mode" />
            </div>
          </div>
          <div style={{ ...styles.hoopSpecRow }}>
            <GiBattery75 size={20} style={{ ...styles.hoopSpecRowIcon }} />
            <div style={{ ...styles.hoopSpecRowText }}>
              <FormattedMessage defaultMessage="Niveau de batterie" />
            </div>
          </div>
          <div style={{ ...styles.hoopSpecRow }}>
            <GiSmartphone size={20} style={{ ...styles.hoopSpecRowIcon }} />
            <div style={{ ...styles.hoopSpecRowText }}>
              <FormattedMessage defaultMessage="Contrôlable via bluetooth" />
              <FiBluetooth size={20} />{" "}
              <FormattedMessage defaultMessage="par l'application iPhone" />{" "}
              <IoLogoApple size={20} />
              <FormattedMessage defaultMessage="et Android" />{" "}
              <IoLogoAndroid size={20} />
            </div>
          </div>

        </div>

        {/* SPACER  */}
        {/* <div style={{ width: 30 }}></div> */}
        {/* BATTERY  */}
        <div
          style={{
            ...styles.hoopSpecBlock,
          }}
        >
          <div
            className="big-font"
            style={{
              ...styles.hoopSpecTitle,
            }}
          >
            <BsBatteryCharging size={50} style={{ marginRight: 10 }} />
            <FormattedMessage defaultMessage="Batterie" />
          </div>

          <div style={{ ...styles.hoopSpecRow }}>
            <FiRefreshCw size={20} style={{ ...styles.hoopSpecRowIcon }} />
            <div style={{ ...styles.hoopSpecRowText }}>
              <FormattedMessage defaultMessage="Interchangeable et rechargeable" />
            </div>
          </div>
          <div style={{ ...styles.hoopSpecRow }}>
            <BiTime size={20} style={{ ...styles.hoopSpecRowIcon }} />
            <div style={{ ...styles.hoopSpecRowText }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <FormattedMessage defaultMessage="Autonomie à puissance maximale:" />
                <ul style={{ padding: 0, paddingLeft: 40, margin: 0 }}>
                  <li>16mm, 5/8: 25 min</li>
                  <li>19mm, 3/4: 50 min</li>
                </ul>
                <FormattedMessage defaultMessage="Autonomie à puissance moyenne:" />
                <ul style={{ padding: 0, paddingLeft: 40, margin: 0 }}>
                  <li>16mm, 5/8: 45 min</li>
                  <li>19mm, 3/4: 1h45</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* Options configurables */}
        <div
          style={{
            ...styles.hoopSpecBlock,
          }}
        >
          <div
            className="big-font"
            style={{
              ...styles.hoopSpecTitle,
            }}
          >
            <MdSettings size={50} style={{ marginRight: 10 }} />
            Options
          </div>

          <div style={{ ...styles.hoopSpecRow }}>
            {/* <TbChartCircles size={20} style={{ ...styles.hoopSpecRowIcon }} /> */}
            <div style={{ ...styles.hoopSpecRowTitle }}>
              <FormattedMessage defaultMessage="Matériaux:" />
            </div>
            <div style={{ ...styles.hoopSpecRowText }}>
              <ul
                style={{
                  padding: 0,
                  margin: 0,
                  paddingLeft: 20,
                  paddingTop: 0,
                }}
              >
                <li>Polypro</li>
                {/* <li>HDPE</li> */}
              </ul>
            </div>
          </div>
          <SpacerV size={10} />
          <div style={{ ...styles.hoopSpecRow }}>
            <div style={{ ...styles.hoopSpecRowTitle }}>
              <FormattedMessage defaultMessage="Épaisseur:" />
            </div>
            <div style={{ ...styles.hoopSpecRowText }}>
              <ul
                style={{
                  padding: 0,
                  margin: 0,
                  paddingLeft: 20,
                  paddingTop: 0,
                }}
              >
                <li>
                  20mm - 3/4 <FormattedMessage defaultMessage="inch" />
                </li>
                <li>
                  16mm - 5/8 <FormattedMessage defaultMessage="inch" />
                </li>
              </ul>
            </div>
          </div>
          <SpacerV size={10} />
          <div style={{ ...styles.hoopSpecRow }}>
            <div style={{ ...styles.hoopSpecRowTitle }}>
              <FormattedMessage defaultMessage="Diamètre:" />
            </div>
            <div style={{ ...styles.hoopSpecRowText }}>
              <ul
                style={{
                  padding: 0,
                  margin: 0,
                  paddingLeft: 20,
                  paddingTop: 0,
                }}
              >
                <li>
                  <FormattedMessage defaultMessage="de 50cm à 90cm" />
                </li>
                <li>
                  {" "}
                  <FormattedMessage defaultMessage="Autres dimensions sur demande" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const AppSection = () => {
  const isMobile = window.innerWidth < 900;
  const navButtonSize = isMobile ? 25 : 50;

  const screenShotImageStyle = {
    height: 450,
    objectFit: "contain",
    ...(!isMobile ? ligthBorderPinkLight : null),
    borderRadius: 10,
    padding: 10,
  } as React.CSSProperties;

  return (
    <FlexColumn center id="app" style={{ width: "100%" }}>
      <SectionTitle>
        <div>
          <GiSmartphone size={50} style={{ marginRight: 10 }} />
          <IoLogoAndroid size={50} style={{ marginRight: 10 }} />
          <IoLogoApple size={50} style={{ marginRight: 30 }} />
        </div>
        {/* <BsCart size={30} style={{ marginRight: 10 }} /> */}
        <FormattedMessage defaultMessage="L'application mobile" />
      </SectionTitle>

      <div style={{ maxWidth: "90vw", ...centerFlex }}>
        <div style={{ textAlign: "center" }}>
          <FormattedMessage
            defaultMessage="Le cerceau est programmable via une application mobile disponible sur
          Android et IOS."
          />
        </div>
        <Flex
          //column
          row={!isMobile ? true : false}
          column={isMobile ? true : false}
          center
          style={{ maxWidth: "90vw" }}
        >
          <div>
            <a href="https://play.google.com/store/apps/details?id=com.andyHoopled.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
              <img
                alt="Disponible sur Google Play"
                src="https://play.google.com/intl/en_us/badges/static/images/badges/fr_badge_web_generic.png"
                style={{ width: "250px" }}
              />
            </a>
          </div>
          <SpacerH size={10} />
          <a
            href="https://apps.apple.com/us/app/hoop-artisan/id6444842033?itsct=apps_box_badge&amp;itscg=30200"
            style={{
              display: "inline-block",
              overflow: "hidden",
              borderRadius: "13px",
              width: "250px",
            }}
          >
            <img
              src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/white/fr-fr?size=250x83&amp;releaseDate=1681862400"
              alt="Download on the App Store"
              style={{ borderRadius: "13px", width: "250px", height: "83px" }}
            />
          </a>
        </Flex>
      </div>
      <SpacerV size={20}></SpacerV>
      <FlexColumn
        center
        style={{
          width: 800,
          maxWidth: "90vw",
          ...ligthBorder,
          borderRadius: 20,
        }}
      >
        <FlexColumn center style={{ maxWidth: 500 }}>
          <div style={{ ...styles.bigFont, fontSize: "18pt", margin: 20 }}>
            <FormattedMessage defaultMessage="Fonctionnalités" />
          </div>
          <Carousel
            showThumbs={false}
            showStatus={false}
            emulateTouch={true}
            renderArrowPrev={(clickHandler, hasPrev) => (
              <CarouselNavButton
                clickHandler={clickHandler}
                active={hasPrev}
                isPrev={true}
              />
            )}
            renderArrowNext={(clickHandler, hasPrev) => (
              <CarouselNavButton
                clickHandler={clickHandler}
                active={hasPrev}
                isPrev={false}
              />
            )}
          >
            <FlexColumn center style={{ height: 650 }}>
              <div>
                <img
                  alt="Hoop manager"
                  src="/app-screenshots/hoopManager.jpg"
                  style={{ ...screenShotImageStyle }}
                />
              </div>
              <SpacerV size={30} />
              <ul style={{ textAlign: "left", maxWidth: isMobile ? 300 : 450 }}>
                <li>
                  <FormattedMessage defaultMessage="Connexion des cerceaux" />
                </li>
                <li>
                  <FormattedMessage defaultMessage="Affichage du niveau de batterie" />
                </li>
                <li>
                  <FormattedMessage defaultMessage="Mise à jour des cerceaux en 1 clic" />
                </li>
              </ul>
            </FlexColumn>

            <FlexColumn center style={{ height: 650 }}>
              <div>
                <img
                  alt="Modes"
                  src="/app-screenshots/modes.jpg"
                  style={{ ...screenShotImageStyle }}
                />
              </div>
              <SpacerV size={30} />
              <ul style={{ textAlign: "left", maxWidth: isMobile ? 300 : 450 }}>
                <li>
                  <FormattedMessage defaultMessage="Bibliothèque ergonomique" />
                </li>
                <li>
                  <FormattedMessage defaultMessage="Des packs de modes HoopArtisan synchronisés" />
                </li>
              </ul>
            </FlexColumn>
            <FlexColumn center style={{ height: 650 }}>
              <div>
                <img
                  alt="Paint"
                  src="/app-screenshots/paint.jpg"
                  style={{
                    height: 200,
                    maxWidth: "90vw",
                    objectFit: "contain",
                    ...(!isMobile ? ligthBorderPinkLight : null),
                    borderRadius: 10,
                    padding: 10,
                  }}
                />
                <SpacerV size={10} />
                <img
                  alt="Simulator"
                  src="/app-screenshots/simulator.jpg"
                  style={{
                    height: 200,
                    maxWidth: "90vw",
                    objectFit: "contain",
                    ...(!isMobile ? ligthBorderPinkLight : null),
                    borderRadius: 10,
                    padding: 10,
                  }}
                />
              </div>
              <SpacerV size={30} />
              <ul style={{ textAlign: "left", maxWidth: isMobile ? 300 : 450 }}>
                <li>
                  <FormattedMessage defaultMessage="Création de modes personnalisés" />
                </li>
                <li>
                  <FormattedMessage defaultMessage="Un simulateur pour visualiser les modes dans l'application" />
                </li>
                <li>
                  <FormattedMessage
                    defaultMessage="Réglages de nombreux paramètres (taille, accéléromètre,
                    son…)"
                  />
                </li>
              </ul>
            </FlexColumn>

            <FlexColumn center style={{ height: 650 }}>
              <div>
                <img
                  alt="Choreography"
                  src="/app-screenshots/choregraphy.jpg"
                  style={{ ...screenShotImageStyle }}
                />
              </div>
              <SpacerV size={30} />
              <ul style={{ textAlign: "left", maxWidth: isMobile ? 300 : 450 }}>
                <li>
                  <FormattedMessage defaultMessage="Programmation de chorégraphies" />
                </li>
                <li>
                  <FormattedMessage defaultMessage="Synchronisation avec la musique" />
                </li>
              </ul>
            </FlexColumn>
          </Carousel>
        </FlexColumn>
      </FlexColumn>
      <SpacerV size={20} />
      <FlexColumn
        center
        style={{
          width: 800,
          maxWidth: "90vw",
          ...ligthBorder,
          borderRadius: 20,
        }}
      >
        <div style={{ ...styles.bigFont, fontSize: "18pt", margin: 20 }}>
          <FormattedMessage defaultMessage="Tutoriel" />
        </div>

        <iframe
          id="ytplayer"
          width="640"
          height="360"
          style={{ maxWidth: "80vw" }}
          src="https://www.youtube.com/embed/lvGhsyx-jgk?autoplay=0&origin=http://hoopartisan.fr"
        ></iframe>

        <SpacerV size={20} />
      </FlexColumn>
    </FlexColumn>
  );
};


export const PartyHoopPage = () => {

    const intl = useIntl();
    useOpenGraphMetaTags({
      title: "Hoop Artisan - Party Hoop",
      url: location.href,
      description: intl.formatMessage({ defaultMessage: "HoopArtisan fabrique des cerceaux lumineux et programmables qui réagissent aux mouvements et au son." }),
      type: "website",
      image: "/partyhoop_banner.webp"
    });
    
    return <RootContainer headerAutoHide={false} style={{marginTop: 0}}>
      
        <h1>
          <FlexRow center style={{marginTop: 60}}>
          <img style={{width: 800, maxWidth: "100vw"}} alt="Party Hoop" src="/partyhoop_banner.webp"></img>
          </FlexRow>
        </h1>
        <FlexRow style={{width: 800, maxWidth: "80vw", justifyContent: "flex-end", alignItems: "center"}}>
          <div><span><FormattedMessage defaultMessage="A partir de"/> </span><span style={{fontSize: 30}}>200€</span></div>
          <SpacerH size={20}/>
          <BigActionButton href="#shop"><FormattedMessage defaultMessage="Commander"/></BigActionButton>
        </FlexRow>
        <SpacerV size={50} />
        <HoopSection/>
        {/* <AppSection/> */}
        {/* <ContactSection/> */}
        <Shop partyHoop={true} />
    </RootContainer>
}
