import React, { CSSProperties, useEffect, useState } from "react";
import "./App.css";
import { SpacerH, SpacerV } from "./Spacer";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { FlexColumn, FlexRow } from "./Flexbox";
// import {auth as firebaseAuth} from "firebase/compat/app"
// import firebase from "firebase/compat/app";
// import * as firebaseui from "firebaseui";
import { ligthBorderPink, styles, Palette } from "./style";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import { BigActionButton } from "./BigActionButton";
import { ContactSection } from "./ContactSection";
import { ROUTES } from "./ROUTES";
import { useOpenGraphMetaTags } from "./OpenGraphTags";
import { useLanguageSelector } from "./intlMessages";
import { LocalizedLink } from "./components/localizedLink";
import { RootContainer } from "./RootContainer";
import { useAuth } from "./db";

// Import the functions you need from the SDKs you need

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries

// Grecaptcha
// declare global {
//   export interface Window {
//     // captchaOnLoad: () => void
//     grecaptcha: ReCaptchaInstance
//   }
// }

// export interface ReCaptchaInstance {
//   ready: (cb: () => any) => any
//   execute: (options: ReCaptchaExecuteOptions) => Promise<string>
//   render: (id: string, options: ReCaptchaRenderOptions) => any
// }

// export interface ReCaptchaExecuteOptions {
//   action: string
// }

// export interface ReCaptchaRenderOptions {
//   sitekey: string
//   size: 'invisible'
// }

/* Palete */
/* pink: rgb(236, 160, 190) */
/* pink: rgb(236, 160, 190) */

export function useScrollTop(listener: (scrolltop: number) => void) {
  const scroller = document; //.querySelector("body");
  return useEffect(() => {
    const eventListener = (event: Event) => {
      const scroll = window.scrollY;
      // console.log(window.scrollY);
      if (scroller && listener) listener(scroll);
    };
    // console.log("body ", scroller);
    document.addEventListener("scroll", eventListener);
    return () => {
      // console.log("unsubscribe scroll");
      document.removeEventListener("scroll", eventListener);
    };
  }, []);
}

export const Banner = () => {
  return (
    <h1
      style={{
        width: 800,
        maxWidth: "100vw",
        display: "flex",
        // height: "600",
        // marginBottom: 50,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-around",
      }}
    >
      <img
        // src="/big_logo_hoop2.png"
        src="/logo_banner.webp"
        style={{
          width: "110vw",
          maxWidth: 800,
        }}
        alt="HoopArtisan Logo"
      ></img>

      <SpacerV size={100} />
      <div
        style={{
          fontSize: 22,
          maxWidth: "80vw",
          textAlign: "center",
          ...styles.bigFont,
          backgroundColor: "#000a",
          boxShadow: "0 0 40px 10px #000",
        }}
      >
        {/* Un hula hoop lumineux réactif à la lumière et au son customisable à l'infini */}

        <div style={{ background: "#0005" }}>
          <FormattedMessage defaultMessage="Des hula hoops lumineux intelligents durables fabriqués à Lyon"></FormattedMessage>
        </div>
      </div>
      <SpacerV size={55} />
      <FlexRow>
      <a href="https://www.instagram.com/hoopartisan">
          <img style={{ width: 40, height: 40 }} src="/instagram_icon.webp" alt="Instagram" />
        </a>
        <SpacerH size={40} />
        <a href="https://www.facebook.com/hoopArtisan">
          <img style={{ width: 40, height: 40 }} src="/facebook_icon.svg" alt="Facebook" />
        </a>
      </FlexRow>
      <SpacerV size={55} />

      <div></div>
      {/* <div
        style={{
          display: "flex",
          flexDirection: window.innerWidth > 900 ? "row" : "column",
          width: "100%",
          justifyContent: "space-around",
          minHeight: 200,
          alignItems: "center",
        }}
      >
        <BigActionButton href="#theHoop">
          <img
            src={require("./icons/live.png")}
            width={25}
            style={{ color: Palette.pink }}
          />
          <SpacerH size={10} />
          <FormattedMessage defaultMessage="Le cerceau" />
        </BigActionButton>
        <BigActionButton href="#contact">
          <MdMail />
          <SpacerH size={10} />
          <FormattedMessage defaultMessage="Contact" />
        </BigActionButton>
        <BigActionButton href="#shop">
          <BsCart />
          <SpacerH size={10} />
          <FormattedMessage defaultMessage="Boutique" />
        </BigActionButton>
      </div> */}
    </h1>
  );
};

export const Menu = ({
  visible,
  setVisible,
}: {
  visible: boolean;
  setVisible: (b: boolean) => void;
}) => {
  const auth = useAuth();

  const langCtx = useLanguageSelector();
  const linkStyle = {
    padding: 20,
  } as React.CSSProperties;
  return (
    <>
      <FlexColumn
        style={{
          transition: "all 0.1s ease-out",
          opacity: visible ? 1 : 0,
          position: "absolute",
          right: 10,
          // top: 10,
          top: visible ? 10 : -1000,
          ...ligthBorderPink,
          borderRadius: 10,
          padding: 20,
          backgroundColor: "#0009",
          ...styles.bigFont,
          zIndex: 10,
        }}
      >
        <LocalizedLink
          href={ROUTES.home}
          onClick={() => setVisible(false)}
          style={{ ...linkStyle }}
        >
          <FormattedMessage defaultMessage="Accueil" />
        </LocalizedLink>
        <LocalizedLink
          href={ROUTES.performer}
          onClick={() => setVisible(false)}
          style={{ ...linkStyle }}
        >
          <FormattedMessage defaultMessage="Performer Hoop" />
        </LocalizedLink>
        <LocalizedLink
          href={ROUTES.party}
          onClick={() => setVisible(false)}
          style={{ ...linkStyle }}
        >
          <FormattedMessage defaultMessage="Party Hoop" />
        </LocalizedLink>
        <LocalizedLink
          href={ROUTES.home + "#contact"}
          onClick={() => setVisible(false)}
          style={{ ...linkStyle }}
        >
          <FormattedMessage defaultMessage="Contact" />
        </LocalizedLink>
        {auth?.currentUser ? (
          <>
            <div>{auth.currentUser.email}</div>
            <LocalizedLink
              href={"/"}
              onClick={(e) => {
                e.preventDefault();
                auth.signOut();
                window.location.reload();
              }}
            >
              <FormattedMessage defaultMessage="Déconnexion" />
            </LocalizedLink>
          </>
        ) : null}
      </FlexColumn>
      <div
        onClick={() => setVisible(false)}
        style={{
          top: visible ? 0 : -1000,
          position: "fixed",
          // top: 0,
          left: 0,
          zIndex: 9,
          width: "100vw",
          height: "100vh",
          backgroundColor: "#0009",
        }}
      ></div>
    </>
  );
};

const HoopsSummary = () => {
  const blockStyle = {
    ...ligthBorderPink,
    backgroundColor: "rgba(0,0,0,0.8)",
    borderRadius: 20,
    padding: 40,
    width: 300,
    maxWidth: "90vw",
    height: 600,
    justifyContent: "space-between",
    transition: "all 0.5s ease-out",
  } as CSSProperties;

  const blockHoverStyle = {
    // backgroundColor: "rgba(255,255,255,0.1)",
    boxShadow: "0px 0px 70px 0px " + Palette.blue,
  } as CSSProperties;

  const titleStyle = {
    ...styles.bigFont,
    fontSize: 30,
    marginBottom: 50,
  } as CSSProperties;
  const priceStyle = {
    ...styles.bigFont,
    fontSize: 40,
    justifyContent: "flex-end",
    // alignItems:"center"
    // color: Palette.pink
  } as CSSProperties;

  const [performerHover, setPerformerHover] = useState(false);
  const [partyHover, setPartyHover] = useState(false);

  return (
    <FlexRow
      style={{
        justifyContent: "space-around",
        alignItems: "flex-start",
        maxWidth: "80vw",
        flexWrap: "wrap",
      }}
    >
      <LocalizedLink href={ROUTES.performer}>
        <FlexColumn
          center
          style={{
            background: "url('/performerhoop_hooponly.webp')",
            backgroundRepeat: "no-repeat",
            backgroundPositionY: "-100px",
            ...blockStyle,
            ...(performerHover ? blockHoverStyle : {}),

            marginBottom: 30,
          }}
          onMouseEnter={() => setPerformerHover(true)}
          onMouseLeave={() => setPerformerHover(false)}
        >
          <div
            style={{ ...titleStyle, top: -10, left: -30, position: "relative" }}
          >
            Performer Hoop
          </div>

          <ul style={{ marginTop: 70 }}>
            <li>
              <FormattedMessage defaultMessage="Tout les modes HoopArtisan" />
            </li>
            <li>
              <FormattedMessage defaultMessage="Création de modes personnalisés" />
            </li>
            <li>
              <FormattedMessage defaultMessage="Programmation de séquences" />
            </li>
            <li>
              <FormattedMessage defaultMessage="Réagit au son et au mouvement" />
            </li>
            <li>
              <FormattedMessage defaultMessage="Application Android et IOS" />
            </li>
          </ul>

          <img
            src="/performerhoop_lightpainting.webp"
            alt="HoopArtisan light painting demo"
            //style={{ width: "80%" }}
            width={250}
            height={250}
          />

          <FlexRow
            style={{
              width: "100%",
              alignItems: "baseline",
              justifyContent: "flex-end",
            }}
          >
            <div style={{ ...priceStyle, textDecoration: "none" }}>450€</div>
          </FlexRow>
          <BigActionButton href={ROUTES.performer}>
            <FormattedMessage defaultMessage="Commander" />
          </BigActionButton>

          {/* <FlexRow style={{alignItems: "baseline", width: "100%", justifyContent: "flex-end"}}> */}
          {/* <div style={{...priceStyle, color: "orange", fontSize: 15}}>Halloween -25%:</div>
<div style={{...priceStyle, color: "orange"}}>375€</div> */}

          {/* <FlexRow style={{alignItems: "baseline", width: "100%", justifyContent: "flex-end"}}>
<div style={{...priceStyle, color: "orange", fontSize: 15}}>Noel -15%:</div>
<div style={{...priceStyle, color: "orange"}}>375€</div> */}

          {/* </FlexRow> */}
        </FlexColumn>
      </LocalizedLink>

      <SpacerH size={100} />
      <LocalizedLink href={ROUTES.party}>
        {/* <img width="400" style={{ float: "right",
          // top: -100, left: -150
          }} src="/partyhoop_hooponly.png"></img> */}
        <FlexColumn
          center
          style={{
            background: "url('/partyhoop_hooponly.webp')",
            backgroundRepeat: "no-repeat",
            // backgroundImage: "/partyhoop_hooponly.png",
            // back
            ...blockStyle,
            ...(partyHover ? blockHoverStyle : {}),
          }}
          onMouseEnter={() => setPartyHover(true)}
          onMouseLeave={() => setPartyHover(false)}
        >
          <FlexRow
            style={{
              ...titleStyle,
              marginBottom: 100,
              width: "100%",
              justifyContent: "flex-start",

              top: 10,
              left: -10,
              position: "relative",
            }}
          >
            Party Hoop
          </FlexRow>

          <ul>
            <li>
              <FormattedMessage defaultMessage="50 modes uniques aléatoires" />
            </li>
            <li>
              <FormattedMessage defaultMessage="Verrouillage sur un mode" />
            </li>
            <li>
              <FormattedMessage defaultMessage="Prévisualisation dans un simulateur" />
            </li>
            <li>
              <FormattedMessage defaultMessage="Application Android et IOS" />
            </li>
          </ul>

          <img
            src="/partyhoop_lightpainting.webp"
            alt="party hoop light painting"
            width={250}
            height={250}
            // style={{ width: "80%" }}
          />

          <FlexRow
            style={{
              width: "100%",
              marginTop: 52,
              alignItems: "baseline",
              justifyContent: "flex-end",
            }}
          >
            <div>
              <FormattedMessage defaultMessage="A partir de" />
            </div>
            <SpacerH size={5} />
            <div style={priceStyle}>200€</div>
          </FlexRow>
          <BigActionButton href={ROUTES.party}>
            <FormattedMessage defaultMessage="Commander" />
          </BigActionButton>
        </FlexColumn>
      </LocalizedLink>
    </FlexRow>
  );
};

export function Homepage() {
  const intl = useIntl();

  useOpenGraphMetaTags({
    title: "Hoop Artisan - Smart LED Hula Hoops Made in Lyon (France)",
    url: location.href,
    description: intl.formatMessage({
      defaultMessage:
        "HoopArtisan fabrique des cerceaux lumineux et programmables qui réagissent aux mouvements et au son.",
    }),
    type: "website",
    image: "/logo_banner.webp",
  });
  // useEffect(() => {
  //   sendContactMail("function@ha.fr", "hello from functions");
  // }, []);
  // useEffect(() => {
  //   setTimeout(() => {

  //     collections.setDoc(collections.doc(collections.contactInbox),
  //       {
  //         email: "test@test.com",
  //         message: "hello world",
  //       }).then(() => {
  //         console.log("message sent !!!")
  //       }, (r) => {
  //         console.log("message not sent !!! ");
  //         console.log(r);
  //       });
  //   }, 1000)

  //   return () => {

  //   }
  // }, [])

  return (
    <RootContainer id="home" headerAutoHide={true} style={{ marginTop: -25 }}>
      <Banner />

      {/* <img src="/hoopfairypromo_crop2.webp" style={{width: 700, maxWidth: "100vw" }}/>
      <div style={{...styles.bigFont, color: Palette.blue, fontSize: 20, margin:20, ...styles.lightBox, backgroundColor: "black"}}>
          <FormattedMessage defaultMessage="-15% sur tous nos cerceaux avec le code"/>
          <span style={{fontSize: 25}}> HOOPFAIRY24</span>
          <span> </span>
          <FormattedMessage defaultMessage="jusqu'au"/>          
          <span> </span>
          <FormattedDate value={new Date().setFullYear(2024,5,27)} />
        </div> */}

      {/* <div style={{...styles.bigFont, color: Palette.blue, fontSize: 20, margin:20, ...styles.lightBox, backgroundColor: "black"}}>
          <span style={{fontSize: 25}}><FormattedMessage defaultMessage="NOEL:"/></span> <FormattedMessage defaultMessage="-15% sur tous nos cerceaux avec le code"/>
          <span style={{fontSize: 25}}> XMAS2023</span>
        </div> */}
      <HoopsSummary />
      <ContactSection />
      {/* <Shop /> */}
      {/* BUY  */}

      {/* 
            <div id="firebaseui-auth-parent-container" hidden style={{
              // display: "none",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#0009",
              position: "fixed",
              width: "100%",
              height: "100%",
              zIndex: 10,
              top: 0,
              left: 0,
            }}>
              <div id="firebaseui-auth-container" style={{

                // position: "fixed",
                margin: "auto",
                // marginLeft: "auto",
                // marginRight: "auto",
                // marginTop: "auto",
                // marginBottom: "auto",
                // width: 300,
                //  backgroundColor: 
              }}></div>
            </div> */}
    </RootContainer>
  );
}

// export default function App() {
//   const locale = LOCALES.FRENCH;

//   return (
//     <>
//     <IntlProvider messages={intlMessages[locale]} locale="en" defaultLocale="en">
//       <Header />
//       <Routes>
//       <Route path={ROUTES.home} element={<Homepage />}></Route>
//       <Route path={ROUTES.perfomer} element={<PerformerHoopPage />}></Route>
//       <Route path={ROUTES.party} element={<PartyHoopPage />}></Route>
//         {/* <Route path="orderSuccess" element={<PaiementSuccess />}></Route> */}
//         {/* <Route path="orderCanceled" element={<PaiementCancelled />}></Route> */}
//       </Routes>
//     </IntlProvider>
//     </>
//   );
// }
