// Remboursement intégral:
//     15 jours - si remboursé dans un état neuf.

import { useLanguageSelector } from "./intlMessages";
import { RootContainer, NormalWidthContainer } from "./RootContainer";
import { H1, H2 } from "./TitleTags";

// Garantie 2-ans:
//     Problèmes électronique: réparation gratuite
//     condition: utilisation normale.

// Garantie a vie:
//     Problème électronique: en fonction de la panne, réparation garantie 6 mois.

// Retubbing: 50 euros


export const FullWarrantyFR = () => {
  return (
    <div style={{ width: "100%" }}>
      <H1>Garantie sur les Objets Lumineux HoopArtisan</H1>

      <p>
        <strong>Durée de la Garantie :</strong> Cette garantie limitée couvre
        les défauts de matériaux et de fabrication des objets lumineux HoopArtisan pour une période de deux ans à compter de la date
        d’achat originale.
      </p>

      <H2>Ce que couvre la garantie</H2>
      <ul>
        <li>
          <strong>Réparations gratuites :</strong> Pendant les deux premières
          années, toutes les réparations nécessaires en raison de défauts de
          fabrication sont gratuites. Après cette période, les réparations sont
          payantes et leur coût varie en fonction de la réparation nécessaire.
        </li>
        <li>
          <strong>Garantie de réparation :</strong> Chaque réparation effectuée
          sous garantie offre une garantie supplémentaire de six mois pour la
          réparation gratuite.
        </li>
        <li>
          <strong>Retour et remboursement :</strong> Si l’article est retourné
          dans un état neuf dans les 30 jours suivant l’achat, il sera remboursé
          intégralement.
        </li>
        <li>
          <strong>Batteries et chargeurs :</strong> Les batteries et les
          chargeurs ne sont pas couverts par cette garantie. Cependant, si ils
          sont défectueux dans les deux mois suivant la date d’achat, ils
          seront remplacés gratuitement.
        </li>
        <li>
          <strong>Renouvellement du tube du hula hoop :</strong> Le coût du
          renouvellement du tube du hula hoop est de 50 euros.
        </li>
        <li>
          <strong>Frais de port :</strong> Les frais de port pour le retour des
          articles sont à votre charge.
        </li>
      </ul>

      <H2>Comment faire valoir la garantie</H2>
      <p>
        Vous devez contacter notre service après-vente avant de retourner un
        article.
      </p>

      <H2>Limitations de la garantie</H2>
      <ul>
        <li>
          Cette garantie est valable uniquement pour les produits en vente sur
          le site internet hoopartisan.fr ainsi que 2 ans après l'arrêt de leur
          vente sur le site internet.
        </li>
        <li>
          Les réparations ne sont pas valables si l’article a été endommagé lors
          d’une utilisation anormale.
        </li>
      </ul>
    </div>
  );
};

export const FullWarrantyEN = () => {
  return (
    <div style={{ width: "100%" }}>
      <H1>Warranty for HoopArtisan Lighted Objects</H1>

      <p>
        <strong>Warranty Duration:</strong> This limited warranty covers defects
        in materials and workmanship of HoopArtisan illuminated objets for a period 
        of two years from the original purchase date.
      </p>

      <H2>What the Warranty Covers</H2>
      <ul>
        <li>
          <strong>Free Repairs:</strong> During the first two years, all
          necessary repairs due to manufacturing defects are free. After this
          period, repairs are chargeable, and the cost varies depending on the
          required repair.
        </li>
        <li>
          <strong>Repair Warranty:</strong> Each repair done under warranty
          provides an additional six-month warranty for the repair.
        </li>
        <li>
          <strong>Returns and Refunds:</strong> If the item is returned in new
          condition within 30 days of purchase, a full refund will be issued.
        </li>
        <li>
          <strong>Batteries and Chargers:</strong> Batteries and chargers are
          not covered by this warranty. However, if they are found to be
          defective within two months of the purchase date, they will be
          replaced free of charge.
        </li>
        <li>
          <strong>Hula Hoop Tube Renewal:</strong> The cost of renewing one hula
          hoop tube is 50 euros.
        </li>
        <li>
          <strong>Shipping Costs:</strong> Shipping costs for returning items
          are at your expense.
        </li>
      </ul>

      <H2>How to Claim the Warranty</H2>
      <p>You must contact our customer service before returning an item.</p>

      <H2>Warranty Limitations</H2>
      <ul>
        <li>
          This warranty is valid only for products sold on the hoopartisan.fr
          website and for 2 years after their discontinuation on the website.
        </li>
        <li>
          Repairs are not valid if the item has been damaged due to abnormal
          use.
        </li>
      </ul>
    </div>
  );
};

export const FullWarrantyPage = () => {
  const lang = useLanguageSelector();
  return (
    <RootContainer>
      <NormalWidthContainer>
        {lang.language === "fr" ? <FullWarrantyFR /> : <FullWarrantyEN />}
      </NormalWidthContainer>
    </RootContainer>
  );
};
