import React, {
  PropsWithChildren,
  useState
} from "react";
import {
  ligthBorderPink,
  styles,
  ligthBorderPink2
} from "./style";

export const BigActionButton = (
  params: PropsWithChildren<{
    href?: string;
    onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  }>
) => {
  const [hover, sethover] = useState(false);

  const style = {
    fontSize: 20,
    borderRadius: 30,
    height: 40,
    // backgroundColor: Palette.blue,
    paddingLeft: 30,
    paddingRight: 30,
    // color: "black",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    whiteSpace: "nowrap",
    cursor: "pointer",
    ...(hover ? ligthBorderPink2 : ligthBorderPink),
    ...styles.bigFont,
  } as React.CSSProperties;

  const props = {
    onClick: params.onClick as any,
    onTouchStart: () => sethover(true),
    onTouchEnd: () => sethover(false),
    onMouseEnter: () => sethover(true),
    onMouseLeave: () => sethover(false),
  };
  if (params.href)
    return (
      <a href={params.href ? params.href : "#"} {...props}>
        <div style={style}>{params.children}</div>
      </a>
    );
  else {
    return (
      <div {...props} style={style}>
        {params.children}
      </div>
    );
  }
};
