import React from 'react';
//import ReactDOM from 'react-dom/client';
import './index.css';
import { Homepage } from './Homepage';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { FlexColumn } from './Flexbox';
import { Palette, styles } from './style';
import { SpacerV } from './Spacer';
import { ThemeProvider } from '@emotion/react';
import { ModalProvider } from './Modal';
import { createTheme } from '@mui/material';
import { LanguageSelector } from './intlMessages';
import { PaiementCancelled, PaiementSuccess } from './Shop';
import { PerformerHoopPage } from './PerformerHoop';
import { PartyHoopPage } from './PartyHoop';
import { ROUTES } from './ROUTES';
import { PrivacyPage } from './Privacy';
import { render } from 'react-dom';
import { BatterySecurity } from './BatterieSecurityNotes';
import { MaxWidthContainer, RootContainer } from './RootContainer';
import { ContactSection } from './ContactSection';
import { FullWarrantyPage } from './Warranty';
// import { logEvent } from 'firebase/analytics';
import { getMyAnalytics } from './db';
import { logEvent } from 'firebase/analytics';


const ErrorPage = () => {
  return <FlexColumn center style={{ width: "100%", height: "100vh", color: Palette.blue, padding: 30, fontSize: 30, ...styles.bigFont }}>

    <img src="/logo_512.webp" style={{
      width: 200, maxWidth: "100vw"
    }}></img>

    <SpacerV size={100} />
    <div>
      Erreur: Cette page n'existe pas.
    </div>
  </FlexColumn>
}
const routeLangPrefix = "/:lang?"
const router = createBrowserRouter([
  {
    path: routeLangPrefix + ROUTES.home,
    element: <Homepage />,
    errorElement: <ErrorPage />
  },
  {
    path: routeLangPrefix + "/orderSuccess",
    element: <PaiementSuccess />,
    errorElement: <ErrorPage />
  },
  {
    path: routeLangPrefix + "/orderCancelled",
    element: <PaiementCancelled />,
    errorElement: <ErrorPage />
  },

  {
    path: routeLangPrefix + ROUTES.performer,
    element: <PerformerHoopPage />,
    errorElement: <ErrorPage />
  },
  {
    path: routeLangPrefix + ROUTES.party,
    element: <PartyHoopPage />,
    errorElement: <ErrorPage />
  },
  {
    path: routeLangPrefix + ROUTES.privacy,
    element: <PrivacyPage />,
    errorElement: <ErrorPage />
  },
  {
    path: routeLangPrefix + ROUTES.batteriesSecurity,
    element: <BatterySecurity />,
    errorElement: <ErrorPage />
  },
  {
    path: routeLangPrefix + ROUTES.warranty,
    element: <FullWarrantyPage />,
    errorElement: <ErrorPage />
  },

  {
    path: routeLangPrefix + ROUTES.contact,
    element: <RootContainer>
        <ContactSection/>
    </RootContainer>,
    errorElement: <ErrorPage />
  },


]);

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: Palette.blue,
      dark: Palette.blue
    },
    secondary: {
      main: Palette.pink,
      dark: Palette.pink

    }

  },
  typography: { fontFamily: "Cinzel" }

});

function App() {
  return   <React.StrictMode>
  <ThemeProvider theme={theme}>
    <LanguageSelector>
    <ModalProvider>
      <div style={{
        // backgroundColor: "black",
        width: "100%",
        // maxWidth: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        background: "no-repeat center center fixed url('/fond.webp')",
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundPositionY: 0,
      }}
      >
        <MaxWidthContainer>
          <RouterProvider router={router} />
        </MaxWidthContainer>
      </div>
    </ModalProvider>
    </LanguageSelector>
  </ThemeProvider>    
</React.StrictMode>
}

const rootElement = document.getElementById('root') as HTMLElement;
// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );

// if (rootElement.hasChildNodes()) {
//   hydrate(<App />, rootElement);
// } else {
  // root.render(<App/>);
  render(<App />, rootElement);
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals


// function sendToAnalytics({ id, name, value }) {
//   logEvent('send', 'event', {
//     eventCategory: 'Web Vitals',
//     eventAction: name,
//     eventValue: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
//     eventLabel: id, // id unique to current page load
//     nonInteraction: true, // avoids affecting bounce rate
//   });
// }

async function sendToGoogleAnalytics(data: {name : string, delta : any, value : any, id : any}) {
  // Assumes the global `gtag()` function exists, see:
  // https://developers.google.com/analytics/devguides/collection/ga4
  //const { analyticsModule} = await getFirebaseClient();
  logEvent(getMyAnalytics(), data.name, {
    // Built-in params:
    value: data.delta, // Use `delta` so the value can be summed.
    // Custom params:
    metric_id: data.id, // Needed to aggregate events.
    metric_value: data.value, // Optional.
    metric_delta: data.delta, // Optional.

    // OPTIONAL: any additional params or debug info here.
    // See: https://web.dev/articles/debug-performance-in-the-field
    // metric_rating: 'good' | 'needs-improvement' | 'poor',
    // debug_info: '...',
    // ...
  });
}

// reportWebVitals(sendToGoogleAnalytics);
