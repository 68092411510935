import { FlexColumn, FlexRow } from "./Flexbox";
import { LocalizedLink } from "./components/localizedLink";
import { ROUTES } from "./ROUTES";
import { LineSeparator } from "./LineSeparator";
import { MaxWidthContainer, NormalWidthContainer } from "./RootContainer";
import { FormattedMessage } from "react-intl";

export const Footer = () => {
  return (
    <MaxWidthContainer>

      <FlexColumn
        center
        style={{ marginTop: 50, marginBottom: 50}}
      >
        <LineSeparator />

        <NormalWidthContainer>
          <FlexColumn style={{ marginTop: 50, width: "100%", fontSize: 14 }}>
            <FlexRow>
              <FlexColumn>
                <LocalizedLink href={ROUTES.performer}>
                <div className="footerLink">Performer Hoop</div>
                </LocalizedLink>
                <LocalizedLink href={ROUTES.party}><div className="footerLink">Party Hoop</div></LocalizedLink>
              </FlexColumn>
              <FlexColumn style={{ width: 30 }}></FlexColumn>
              <FlexColumn>
                <LocalizedLink href={ROUTES.privacy}><div className="footerLink"><FormattedMessage defaultMessage="Vie privée"/></div></LocalizedLink>
                <LocalizedLink href={ROUTES.warranty}><div className="footerLink"><FormattedMessage defaultMessage="Garantie"/></div></LocalizedLink>
                <LocalizedLink href={ROUTES.batteriesSecurity}>
                <div className="footerLink"><FormattedMessage defaultMessage="Sécurité et batteries"/></div>
                </LocalizedLink>
                <LocalizedLink href={ROUTES.contact}><div className="footerLink">Contact</div></LocalizedLink>
              </FlexColumn>
            </FlexRow>

            <div
              style={{
                marginTop: 20,
                width: "100%",
                textAlign: "center",
              }}
            >
              Copyright 2022-2024 Matthieu Garrigues
            </div>
          </FlexColumn>
        </NormalWidthContainer>
      </FlexColumn>
    </MaxWidthContainer>
  );
};
