import {
  useEffect, PropsWithChildren,
  useState
} from "react";
import { Link } from "react-router-dom";
import { LocalizedLink } from "./components/localizedLink";
import { localizeUrlPath, useLanguageSelector } from "./intlMessages";

export const LinkOrA = (params: PropsWithChildren<{ to: string; }>) => {
  const [refresh, setRefresh] = useState({});
  useEffect(() => {
    const listener = () => setRefresh({});
    window.addEventListener("popstate", listener);
    return () => {
      window.removeEventListener("popstate", listener);
    };
  }, [setRefresh]);

  const langCtx = useLanguageSelector();
  if (window.location.pathname === "/")
    return <LocalizedLink href={params.to}>{params.children}</LocalizedLink>;
  else return <Link to={localizeUrlPath(params.to, langCtx)}>{params.children}</Link>;
};
