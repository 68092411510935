

export enum HoopModel {
  PARTY = 1,
  PERFORMER = 2,
}

export type PartyHoopOptions = {
  ledDensity: 30 | 60,
  nBatteries: 1 | 4,
};

export enum ShippingLocations{
  MONDIALRELAY_COUNTRIES,
  UE_UK_SWITZERLAND,
  EUROPE,
  WORLD
}

export function getHoopShippingCost(location : ShippingLocations, numberOfHoops : number) {

  if (location === ShippingLocations.MONDIALRELAY_COUNTRIES)
    return 0;

  const hoop_weight = numberOfHoops * 0.6;
  const box_weight = 0.2 * numberOfHoops / 2;

  const weight = hoop_weight + box_weight;

  if (location == ShippingLocations.UE_UK_SWITZERLAND) {
    if (weight < 1) return 7;
    if (weight < 2) return 10;
    if (weight < 5) return 15;
    if (weight < 10) return 32;
  }
  if (location == ShippingLocations.EUROPE) {
    if (weight < 1) return 15;
    if (weight < 2) return 20;
    if (weight < 5) return 26;
    if (weight < 10) return 49;
  }
  if (location == ShippingLocations.WORLD) {
    if (weight < 1) return 25;
    if (weight < 2) return 39;
    if (weight < 5) return 61;
    if (weight < 10) return 123;
  }

  return 150;
}

export function singleHoopPrice(order : HoopOrder) {
  if (order.options) {
    const ledPrice = order.options.ledDensity === 60 ? 20 : 0;
    const batteryPrice = order.options.nBatteries === 4 ? 20 : 0;
    return 200 + ledPrice + batteryPrice;
  }

  return order.hoopInternalDiameter < 60 ? 400 : 450;
};

// export function computeOrderPrice(order : HoopOrder) {
//   const computePrice = () => {
//     return Math.round(order.numberOfHoops * singleHoopPrice(order));
//   };
//   const computeDiscountRatio = (discountFromCode: number) => {
//     const multipleHoopDiscount = order.options ? 0 : 5 * (order.numberOfHoops - 1);
//     return Math.min(0.3, (discountFromCode + multipleHoopDiscount) / 100);
//   };

//   return order.promotionCode
// }
export type HoopOrder = {
  numberOfHoops: number,
  model: HoopModel,
  options? : PartyHoopOptions,
  hoopInternalDiameter: number,
  tubeDiameter: number,
  material: string,
  promotionCode: string,
  phone: string,
  address: string,
  grip: boolean,
  shippingCost: number,
  language: string,
  diameterUnit: string,
};
