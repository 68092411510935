
export class ListenerManager<F> {

    callbacks: ((f: F) => void)[] = []
  
    trigger(f: F) {
      for (let c of this.callbacks) {
        c(f);
      }
    }
    clear() { this.callbacks.length = 0; }
    addListener(l: (f: F) => void) {
      this.callbacks.push(l);
      return () => this.removeListener(l);
    }
  
    removeListener(f: (f: F) => void) {
      const idx = this.callbacks.findIndex(x => x === f);
      if (idx >= 0)
        this.callbacks.splice(idx, 1);
    }
  }

  
  export function round1(v : number) {
    return Math.round(v * 10) / 10;
  }
  export function round2(v : number) {
    return Math.round(v * 100) / 100;
  }