import React, {
  useState,
  useCallback
} from "react";
import {
  MdCheck,
  MdError, MdMail, MdSend
} from "react-icons/md";
import {
  CircularProgress,
  FormControl,
  Input,
  InputLabel, TextField
} from "@mui/material";
import { SpacerV } from "./Spacer";
import { FlexColumn } from "./Flexbox";
import { sendContactMail } from "./db";
import { styles, Palette } from "./style";
import { FormattedMessage, useIntl } from "react-intl";
import { SectionTitle } from "./SectionTitle";
import { BigActionButton } from "./BigActionButton";

export const ContactSection = () => {
  const [email, setemail] = useState("");
  const [message, setmessage] = useState("");
  const [error, seterror] = useState("");
  const [status, setstatus] = useState("error");
  const intl = useIntl();
  const submitContactForm = useCallback(
    async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      // send contact form.
      e.preventDefault();
      const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      const email2 = email.trim();
      const message2 = message.trim();
      if (!message2.length) {
        seterror("Veuillez saisir un message.");
        setstatus("error");
        return;
      }
      if (emailRegex.test(email2)) {
        setstatus("sending");
        // await new Promise(r => setTimeout(r, 2000))
        try {
          await sendContactMail(email2, message2);
          // throw new Error("test error");
          console.log("message sent.");
          setstatus("sent");
          setmessage("");
        } catch (reason) {
          console.error("Cannot send message:", reason);
          setstatus("error");
          seterror(
            intl.formatMessage({ defaultMessage: "Erreur. Veuillez réessayer plus tard ou écrivez directement à contact@hoopartisan.fr" })
          );
        }
      } else {
        seterror(intl.formatMessage({ defaultMessage: "Adresse email invalide." }));
        setstatus("error");
      }
    },
    [email, message]
  );

  return (
    <div
      id="contact"
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <SectionTitle>
        <MdMail size={50} style={{ marginRight: 30 }} />
        {/* <MaterialIcons name="support" size={24} color="white" /> */}
        {/* <img src={require("./icons/live.png")} width={50} style={{ marginRight: 10 }} /> */}
        Contact
      </SectionTitle>
      <p
        style={{
          width: 800,
          maxWidth: "80vw",
          textAlign: "center",
          textJustify: "inter-word",
        }}
      >
        <FormattedMessage defaultMessage="Des questions ?" /><br />
        <br /> <FormattedMessage defaultMessage="Contactez-nous via le formulaire ci-dessous ou directement par mail." />
      </p>
      <div
        style={{
          width: "100%",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          padding: 20,
          borderStyle: "solid",
          borderWidth: 0,
          borderColor: "white",
          fontSize: 30,
        }}
      >
        contact@hoopartisan.fr
      </div>

      <FlexColumn
        style={{ maxWidth: "90vw", width: 600, ...styles.normalFont }}
      >
        <FormControl>
          <InputLabel htmlFor="my-input">Email</InputLabel>
          <Input
            style={{ ...styles.normalFont, color: Palette.blue }}
            id="my-input"
            aria-describedby="my-helper-text"
            value={email}
            onChange={(e) => setemail(e.target.value)} />
          {/* <FormHelperText id="my-helper-text">We'll never share your email.</FormHelperText> */}
        </FormControl>
        <SpacerV size={30} />
        <TextField multiline label="Message" minRows={5} value={message} onChange={(e) => {
              setmessage(e.target.value);
              setstatus("");
            }}  />
      
        <SpacerV size={30} />

        <BigActionButton onClick={submitContactForm}>
          {status === "sending" ? (
            <CircularProgress size={20} />
          ) : status === "sent" ? (
            <>
              <MdCheck size={30} color="green" />
              <FormattedMessage defaultMessage="Message envoyé" />
            </>
          ) : (
            <>
              <MdSend />
            </>
          )}
        </BigActionButton>

        {status !== "" ? (
          <div
            style={{
              marginBottom: 30,
            }}
          >
            {/* {status === "sent" ? <div color="red"><MdCheck color="green" />Message transmis. Nous reviendrons vers vous prochainement.</div> : null} */}
            {status === "error" && error !== "" ? (
              <>
                <MdError color="red" /> {error}
              </>
            ) : null}
          </div>
        ) : null}
      </FlexColumn>
    </div>
  );
};
