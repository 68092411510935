// import { GoogleAuthProvider, FacebookAuthProvider, EmailAuthProvider } from "firebase/auth"
import {auth as firebaseuiAuth} from "firebaseui"
import "firebaseui/dist/firebaseui.css";
import { getFirebase } from "./db";
import { EmailAuthProvider, FacebookAuthProvider, GoogleAuthProvider } from "firebase/auth";

// type FirebaseuiAuth = (typeof import('firebaseui').auth);

// FirebaseUI config.
export const authUIConfig: /*firebaseuiAuth.Config*/ any = {
    callbacks: {
      signInSuccessWithAuthResult: (authresult : any, redirectUrl : any) => {
        return true;
      },
    },
    // signInSuccessUrl: `#signinsuccess`,
    signInOptions: [
      // Leave the lines as is for the providers you want to offer your users.
      // GoogleAuthProvider.PROVIDER_ID,
      // FacebookAuthProvider.PROVIDER_ID,
      // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
      // firebase.auth.GithubAuthProvider.PROVIDER_ID,
      // EmailAuthProvider.PROVIDER_ID,
      //PhoneAuthProvider.PROVIDER_ID,
      //firebaseuiAuth.AnonymousAuthProvider.PROVIDER_ID,
    ],
  
    // tosUrl and privacyPolicyUrl accept either url string or a callback
    // function.
    // Terms of service url/callback.
    tosUrl: "/conditions",
    // Privacy policy url/callback.
    privacyPolicyUrl: function () {
      window.location.assign("/privacy");
    },
  };
  
  // function hideAuthContainer() {
  //   const div = document.getElementById("firebaseui-auth-container");
  //   if (div) div.hidden = true;
  //   else console.error("No auth div");
  // }
export function showAuthContainer() {
    const div = document.getElementById("firebaseui-auth-container");
    if (div) {
      div.hidden = false;
    } else console.error("No auth div");
    const div2 = document.getElementById("firebaseui-auth-parent-container");
    if (div2) {
      div2.hidden = false;
      div2.style.display = "flex";
    } else console.error("No auth div");
  }
  
  // function startAuthentication() {
  //   // Initialize the FirebaseUI Widget using Firebase.
  //   // The start method will wait until the DOM is loaded.
  //   console.log("ui start");
  //   showAuthContainer();
  //   ui.start("#firebaseui-auth-container", uiConfig);
  // }
  

//console.log("GET AUTH UI...");
const firebase = getFirebase();
//const authUi = getFirebase().then(async firebase => {
  //console.log("GET firebase ok...");
const auth = firebase.auth;
//console.log("GET firebase UI...");
//const firebaseui = await import("firebaseui")
//console.log("GET firebase UI ok...");
//const firebaseuiAuth = firebaseui.auth;
const ui = new firebaseuiAuth.AuthUI(auth);
// const {authModule} = await getFirebaseClient();
//console.log("GET firebase client...");
authUIConfig.signInOptions = [
    // Leave the lines as is for the providers you want to offer your users.
    GoogleAuthProvider.PROVIDER_ID,
    FacebookAuthProvider.PROVIDER_ID,
    EmailAuthProvider.PROVIDER_ID,   
];

if (ui.isPendingRedirect()) {
  document.addEventListener("load", showAuthContainer);
  ui.start("#firebaseui-auth-container", authUIConfig);
}
//console.log("AUTH UI OK!");
const authUI = ui;
//}

// }).catch(e => {
//   console.error("Error in get Auth ui:", e);
//   throw e;
// });

export function getMyAuthUI() {
    return authUI;
}
