import { Button } from "@mui/material";
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { FaCaretDown } from "react-icons/fa";

import { IntlProvider } from "react-intl";
import { FlexColumn } from "./Flexbox";
import { SpacerH } from "./Spacer";
import { ListenerManager } from "./tools";

export const LOCALES = {
  ENGLISH: "en",
  FRENCH: "fr",
  // GERMAN: "de-DE",
};
export const allLocales = ["en", "fr"];

export const intlMessages = {
  [LOCALES.FRENCH]: require("./locales/fr.json"),
  [LOCALES.ENGLISH]: require("./locales/en.json"),
};

const languageChangeEvent = new ListenerManager();

const languageContext = React.createContext({
  language: LOCALES.FRENCH,
  setLanguage: (l: string) => {}
});

export function useLanguageSelector() {
  const [refresh, setRefresh] = useState({});

  const ctx = useContext(languageContext);
  useEffect(() => {
    return languageChangeEvent.addListener(() => setRefresh({}));  
  }, [ctx]);
  
  return ctx;
}

export type LanguageContextType = ReturnType<typeof useLanguageSelector>;

export function changeLangInUrlPath(path : string, newLang : string) {

    // instead replace language in url and redirect
    const urlLang = path.split("/")[1];
    let newPath = "";
    if (allLocales.includes(urlLang))
      {
        const split = path.split("/");
        if (newLang !== "en") {
          split[1] = newLang;
          //console.log("ADD LANG to URL:", newLang);
        }
        else {
          split.shift();
          split[0] = "";
          //console.log("REMOVE LANG FROM URL")
        };
        newPath = split.join("/");
      }
    else {
      if (newLang !== "en") {
        newPath = `/${newLang}` + path;
      } else {
        newPath = path;
      }
    }
    return  newPath;
}

export function localizeUrlPath(path: string, ctx : LanguageContextType) {
  return changeLangInUrlPath(path, ctx.language);
}

// select a language based on:
//     - navigator preferences.
//     - saved language in localstorage.
//     - language in url
export const LanguageSelector = (props: PropsWithChildren<{}>) => {
  const defaultLanguage = navigator.language;

  const savedLanguage = localStorage.getItem("lang");

  const urlSplit = document.location.pathname.split("/");
  const firstUrlPart = document.location.pathname.startsWith("/") ? urlSplit[1] : urlSplit[0];
  const languageInUrl =  (firstUrlPart === LOCALES.FRENCH || firstUrlPart === LOCALES.ENGLISH) ? firstUrlPart : null;

  if (languageInUrl) {
    localStorage.setItem("lang", languageInUrl);
  }
  const [language, setLanguage_] = useState((languageInUrl || savedLanguage || defaultLanguage).startsWith("fr")
  ? LOCALES.FRENCH
  : LOCALES.ENGLISH);

  const setLanguage = useCallback(
    (l: string) => {
      localStorage.setItem("lang", l);
      // setLanguage_(l);
      // instead replace language in url and redirect
      document.location.pathname = changeLangInUrlPath(document.location.pathname, l);
      // const path = document.location.pathname;
      // const lang = path.split("/")[1];
      // if (allLocales.includes(lang))
      //   {
      //     const split = path.split("/");
      //     split[1] = l;
      //     document.location.pathname = split.join("/");
      //   }
      // else {
      //   document.location.pathname = `/${l}` + document.location.pathname;
      // }
    },
    [setLanguage_]
  );

  return (
    <languageContext.Provider value={{ language: language, setLanguage }}>
      <IntlProvider
        messages={intlMessages[language]}
        locale={language}
        defaultLocale={language}
      >
        {props.children}
      </IntlProvider>
    </languageContext.Provider>
  );
};

export const LanguageSeletorWidget = () => {
  const ctx = useLanguageSelector();

  const [menuOpen, setmenuOpen] = useState(false);

  return <div>
      <FlexColumn style={{display: menuOpen ? "flex" : "none"}}>
        <Button
          href={changeLangInUrlPath(document.location.pathname, "en")}
          onClick={() => {
            ctx.setLanguage(LOCALES.ENGLISH);
            setmenuOpen(false);
          }}
        >
          <img src="/gb.svg" alt="English" width={20}/><SpacerH size={5}/>EN
        </Button>
        <Button
          href={changeLangInUrlPath(document.location.pathname, "fr")}
          onClick={() => {
            ctx.setLanguage(LOCALES.FRENCH);
            setmenuOpen(false);
          }}
        >
          <img src="/fr.svg" alt="Français" width={20}/><SpacerH size={5}/>FR
        </Button>
      </FlexColumn>

      <Button style={{width: 60, display: !menuOpen && ctx.language == LOCALES.FRENCH? "flex" : "none"}}
        onClick={() => setmenuOpen(true)}>
        <img src="/fr.svg" alt="Français"  width={20}/>
      <SpacerH size={5}/>FR        
      <FaCaretDown />
      </Button>

        <Button
          style={{width: 60, display: !menuOpen && ctx.language == LOCALES.ENGLISH? "flex" : "none", zIndex: 10000 }}
          onClick={() => {
            setmenuOpen(true);
            return true;
          }}
        >
        <img src="/gb.svg" alt="English" width={20}/><SpacerH size={5}/>EN
        <FaCaretDown />
        </Button>
      </div>
    
  // if (menuOpen) {
  //   return (
  //     <FlexColumn>
  //       <Button
  //         href={changeLangInUrl(document.location.href, "en")}
  //         onClick={() => {
  //           ctx.setLanguage(LOCALES.ENGLISH);
  //           setmenuOpen(false);
  //         }}
  //       >
  //         <img src="/gb.svg" alt="English" width={20}/><SpacerH size={5}/>EN
  //       </Button>
  //       <Button
  //         href={changeLangInUrl(document.location.href, "fr")}
  //         onClick={() => {
  //           ctx.setLanguage(LOCALES.FRENCH);
  //           setmenuOpen(false);
  //         }}
  //       >
  //         <img src="/fr.svg" alt="French" width={20}/><SpacerH size={5}/>FR
  //       </Button>
  //     </FlexColumn>
  //   );
  // } else {
  //   if (ctx.language == LOCALES.FRENCH)
  //     return <Button onClick={() => setmenuOpen(true)}><img src="/fr.svg" width={20}/><SpacerH size={5}/>FR        <FaCaretDown />
  //     </Button>;
  //   else
  //     return (
  //       <Button
  //         style={{ zIndex: 10000 }}
  //         onClick={() => {
  //           setmenuOpen(true);
  //           return true;
  //         }}
  //       >
  //       <img src="/gb.svg" width={20}/><SpacerH size={5}/>EN
  //       <FaCaretDown />
  //       </Button>
  //     );
  // }
};
