import { useEffect } from "react"

enum OpenGraphTag {
    TITLE = "og:title",
    TYPE = "og:type",
    URL = "og:url",
    DESCRIPTION = "og:description",
    IMAGE = "og:image"
}

type OpenGraphMetaTags = {
   title: string,
   type: string
   url: string,
   description: string, 
   image: string
}

function getMetaAttributeByName(m : HTMLMetaElement, name : string) {
    for (let i = 0; i < m.attributes.length; i++) {
        if (m.attributes[i].nodeName === name) {    
            return m.attributes[i];
        }
    }
    return null;
}

export function useMetaTag(tagAttributeName: "property" | "name", tag: string, content: string) {
    
    useEffect(() => {
        const metas = Array.from(document.getElementsByTagName('meta'))
        metas.forEach(m => {
            const propertyAttr = getMetaAttributeByName(m, tagAttributeName);
            // if m has the right opengraph tag in it's property attribute
            if (propertyAttr && propertyAttr.nodeValue === tag) {
                // set the content to the desired value.
                m.setAttribute("content", content);
            }
        });
        // if no meta has the right opengraph tag, create one.
        const newMeta = document.createElement("meta");
        newMeta.setAttribute(tagAttributeName, tag);
        newMeta.setAttribute("content", content);
        document.head.appendChild(newMeta);
    }, []);

}
export function useTitleTag(title: string) {
    
    useEffect(() => {
        document.title = title;
        // let titleTag = document.getElementsByTagName('title')[0];
        // if (!titleTag) {
        //     document.createElement("title");
        //     document.head.appendChild(titleTag);
        // }

        // titleTag.innerText = title;
    }, []);

}

export function useOpenGraphMetaTags(tag: OpenGraphMetaTags) {
    useMetaTag("property", OpenGraphTag.TITLE, tag.title);
    useMetaTag("property", OpenGraphTag.DESCRIPTION, tag.description);
    useMetaTag("property", OpenGraphTag.TYPE, tag.type);
    useMetaTag("property", OpenGraphTag.URL, tag.url);
    useMetaTag("property", OpenGraphTag.IMAGE, tag.image);

    useTitleTag(tag.title);

    useMetaTag("name", "description", tag.description);
}
