import { useState } from "react";
import {
  IoIosArrowBack,
  IoIosArrowForward
} from "react-icons/io";
import { FlexColumn, FlexRow } from "./Flexbox";
import { ligthBorderPink, ligthBorderPink2, Palette } from "./style";

export const CarouselNavButton = ({
  clickHandler, active, isPrev,
}: {
  clickHandler: () => void;
  active: boolean;
  isPrev: boolean;
}) => {
  const [hover, setHover] = useState(false);
  const isMobile = window.innerWidth < 900;
  const navButtonSize = isMobile ? 25 : 50;

  if (!active && hover) {
    setHover(false);
  }

  if (!active) return <></>;

  return (
    <FlexRow
      center
      style={{
        height: "100%",
        position: "absolute",
        padding: 10,
        zIndex: 100,
        cursor: "pointer",
        top: 0,
        ...(!isPrev
          ? { right: isMobile ? 35 : 0 }
          : { left: isMobile ? 35 : undefined }),
      }}
      onClick={clickHandler}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <FlexColumn
        center
        style={{
          ...(hover ? ligthBorderPink2 : ligthBorderPink),
          transition: "all 0.3s ease-out",
          borderRadius: navButtonSize / 2,
          width: navButtonSize,
          height: navButtonSize,
          marginTop: -140,
        }}
      >
        {isPrev ? (
          <IoIosArrowBack
            size={navButtonSize}
            color={Palette.pink}
            style={{ marginLeft: -5 }} />
        ) : (
          <IoIosArrowForward
            size={navButtonSize}
            color={Palette.pink}
            style={{ marginRight: -2 }} />
        )}
      </FlexColumn>
    </FlexRow>
  );
};
