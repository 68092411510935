import { Header } from "./Header";
import { useLanguageSelector } from "./intlMessages"
import { styles } from "./style"

export const PrivacyPage = function () {

  const langCtx = useLanguageSelector();

    return <div style={{maxWidth: 600}}>
          <Header />

    <img
        // src="/big_logo_hoop2.png"
        src="/logo_banner.webp"
        style={{
          width: "110vw",
          maxWidth: 800,
        }}
        alt="logo"
      ></img>
    


    {langCtx.language == "en" && <div><div style={{...styles.bigFont, fontSize: 30}}>Privacy Policy</div><div style={{textAlign: "justify"}}>
This app does not collect any personal data from its users. We do not store any information about your device, location, or usage. We do not use cookies or any other tracking technologies.
<br/>
We respect your privacy and do not share any information with third parties. We do not display any ads or use any analytics tools.
<br/>
If you have any questions or concerns about our privacy policy, please contact us at hoopartisan1@gmail.com
<br/>
Thank you for using our app.
    </div></div>}

    {langCtx.language == "fr" && <div><div style={{...styles.bigFont, fontSize: 30, marginTop: 50}}>Politique de confidentialité</div>
<div style={{textAlign: "justify"}}>

Cette application ne collecte aucune donnée personnelle de ses utilisateurs. Nous ne stockons aucune information sur votre appareil, votre emplacement ou votre utilisation. Nous n'utilisons pas de cookies ni d'autres technologies de suivi.
<br/>

Nous respectons votre vie privée et ne partageons aucune information avec des tiers. Nous n'affichons pas de publicités ni n'utilisons d'outils d'analyse.
<br/>

Si vous avez des questions ou des préoccupations concernant notre politique de confidentialité, veuillez nous contacter à l'adresse e-mail suivante : hoopartisan1@gmail.com
<br/>
Merci d'utiliser notre application.
</div></div>}

    </div>
}