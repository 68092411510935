import { PropsWithChildren } from "react";
import { SpacerV } from "./Spacer";
import { FlexColumn } from "./Flexbox";
import { styles, Palette } from "./style";
import { LineSeparator } from "./LineSeparator";

export const SectionTitle = (params: PropsWithChildren<{skipLineSeparator?: boolean }>) => {
  return (
    <FlexColumn
      center
      style={{ width: "100%", marginTop: 100, marginBottom: 20 }}
    >
      {!params.skipLineSeparator && <LineSeparator style={{ width: "100%" }} />}
      
      <SpacerV size={60} />
      <div
        style={{
          ...styles.bigFont,
          fontSize: 50,
          width: "100%",
          marginTop: 20,
          marginBottom: 20,
          color: Palette.blue,
          display: "flex",
          maxWidth: "90vw",
          // fontWeight: 600,
          flexDirection: "row",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        {params.children}
      </div>

      {/* <div style={{ width: 500, maxWidth: "60vw", padding: "2px 30px 2px 30px", marginTop: 20,  margin: "auto", borderRadius: 10 }}>
            <LineSeparator style={{ width: "100%" }} />
          </div> */}
    </FlexColumn>
  );
};
