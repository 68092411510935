import React, { PropsWithChildren } from "react";


type DivProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

type FlexProps = PropsWithChildren<{
  
  wrap?: boolean;
  column?: boolean
  row?: boolean,
  center?: boolean
  centerH?: boolean
  centerV?: boolean
  fillParent?: boolean

} & DivProps>;

export const Flex = (params : PropsWithChildren<FlexProps>) => {

  const { wrap, column, center, centerH, centerV, fillParent, row, style, ...divParams} = params;
  const flexstyle : React.CSSProperties = {
    display: "flex",
    flexDirection: params.row ? "row" : "column",
    ...(wrap  ? { flexWrap: "wrap" } : {}),
    ...(center ? { justifyContent: "center", alignItems: "center"} : {}),
    ...(centerH && row ? {  justifyContent: "center"} : {}),
    ...(centerH && !row ? {  alignItems: "center"} : {}),
    ...(centerV && row ? {  alignItems: "center"} : {}),
    ...(centerV && !row ? {  justifyContent: "center"} : {}),
    ...(fillParent ? { width: "100%", height: "100%" } : {}),
    ...style
  }

  return <div {...divParams} style={flexstyle}>
    {params.children}
  </div>
}

export const FlexColumn = (params : PropsWithChildren<FlexProps>) => {
  return <Flex {...params} column>{params.children}</Flex>
}

export const FlexRow = (params : PropsWithChildren<FlexProps>) => {
  return <Flex {...params} row>{params.children}</Flex>
}